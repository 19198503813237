<template>
  <section class="map-filters-section">
    <h2 class="title">Trouvez le voyage qui vous correspond</h2>
    <span class="sub-title">Entrez une destination, vos dates et/ou votre type de voyage</span>
    <SearchBar
      v-bind="$attrs"
      class="search-bar"
      :travel-count="0"
      :selected-filter="selectedFilter"
      is-search-bar
      :fetched-filter-data="{
        destinations: data.destinations,
        seasons: data.seasons ?? [],
        typeCategories: data.typeCategories ?? []
      }"
    />
    <div id="map-wrapper" class="map-wrapper">
      <Icon
        id="world-map"
        name="world-map"
        width="100%"
        height="100%"
        :class="[
          ...countriesByJourneys.map(countryByJourney => countryByJourney.iso.toUpperCase()),
          isLoading ? 'map-continent-loading' : 'map-continent'
        ]"
        @mouseover="onMouseOver"
        @click="onClick"
      />
      <div v-if="countriesByJourneys.length > 0" ref="elementsRefs" class="toast">
        <span v-for="countryByJourney in countriesByJourneys" :key="countryByJourney.iso">
          {{ countryByJourney.name }}
          ({{ countryByJourney.count }})
        </span>
      </div>
      <OtherDestinationsMapCard
        v-if="otherDestinations.length > 0"
        class="other-destinations"
        :countries="otherDestinations"
      ></OtherDestinationsMapCard>
    </div>
  </section>
  <div id="pointer-container" class="pointer-container">
    <span>
      {{ hoveredCountryByJourney ? hoveredCountryByJourney.name : null }}
    </span>
    <span>{{ hoveredCountryByJourney?.count ? `(${hoveredCountryByJourney.count})` : null }}</span>
  </div>
</template>

<script lang="ts" setup>
import { IsoCode } from '@/lib/variables';

import { Country } from '@/lib/strapi-types/Country';

import { Filter, FilterDefinition, FiltersObject } from '@/lib/types/filters';
import { CountryByJourney, SlugItem } from '@/lib/types/models/common';

interface ExtendedMouseEvent extends MouseEvent {
  target: EventTarget & { id: string; nodeName: string };
}

const props = defineProps<{
  selectedFilter: FiltersObject;
  data: {
    destinations: SlugItem[];
    seasons: FilterDefinition<'seasons'>[];
    typeCategories: FilterDefinition<'typeCategories'>[];
  };
  countriesByJourneys: CountryByJourney[];
  otherDestinations: Country[];
}>();

const emit = defineEmits<(e: 'on-destination-click', isoCode: IsoCode) => void>();

function isExtendedMouseEvent(event: MouseEvent): event is ExtendedMouseEvent {
  return (
    typeof (event as ExtendedMouseEvent).target.id === 'string' &&
    typeof (event as ExtendedMouseEvent).target.nodeName === 'string'
  );
}

const elementsRefs = ref<HTMLElement[]>([]);
const hoveredCountryByJourney = ref<CountryByJourney>();

function onMouseOver(event: MouseEvent) {
  const targetEl = document.getElementById('pointer-container');
  if (isExtendedMouseEvent(event) && event.target.id && event.target.id.length <= 4) {
    const countryName = new Intl.DisplayNames(['fr'], { type: 'region' }).of(event.target.id);

    if (!countryName) return;

    hoveredCountryByJourney.value = {
      name: countryName,
      iso: event.target.id,
      count:
        props.countriesByJourneys.find(
          countryByJourney => countryByJourney.iso === event.target.id.toUpperCase()
        )?.count ?? 0
    };

    if (targetEl) {
      targetEl.style.display = 'flex';
      targetEl.style.left = event.pageX + 20 + 'px';
      targetEl.style.top = event.pageY + 'px';
    }
    hoverAllCountry(event.target.id);
  } else if (targetEl) {
    targetEl.style.display = 'none';
    hoverAllCountry(null);
  }
}

function hoverAllCountry(iso: string | null) {
  const mapContainer = document.getElementById('LMDV-D o-map');
  if (!mapContainer) return;

  const allItems = mapContainer.querySelectorAll(`[class*="hovered"]`);
  allItems.forEach(node => {
    node.classList.remove('hovered');
  });

  if (iso === null) return;

  const selectedItems = mapContainer.querySelectorAll(`[id="${iso}"]`);
  selectedItems.forEach(node => {
    node.classList.add('hovered');
  });
}

watch(
  () => props.selectedFilter,
  newSelected => {
    const selectedIsoCodes = (newSelected.destinations as Filter<SlugItem>[])
      .filter(item => item.value.code)
      .map(item => item.value.code as string);

    updateElementsClasses(selectedIsoCodes);
  }
);

function updateElementsClasses(codes: string[]) {
  const mapContainer = document.getElementById('LMDV-D o-map');
  if (!mapContainer) return;

  const selectedItems = mapContainer.querySelectorAll('path[class*="selected"]');
  selectedItems.forEach(node => {
    if (!codes.includes(node.id)) {
      node.classList.remove('selected');
    }
  });

  const elements = codes.map(code => mapContainer.querySelectorAll(`[id="${code}"]`));
  elements.forEach(nodeList => {
    nodeList.forEach(el => {
      if (!el.classList.contains('selected')) {
        el.classList.add('selected');
      }
    });
  });
}

function onClick(event: MouseEvent) {
  if (!isExtendedMouseEvent(event) || event.target.nodeName !== 'path') return;

  emit('on-destination-click', event.target.id as IsoCode);
}

const isLoading = computed(() => useLoadingStore().getIsLoading('gridResults'));
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/spacings.scss';
@use '$/button.scss';
@use '$/typography.scss';
@use '$/breakpoints.scss';
@use '$/border-radius.scss';
@use 'sass:string';
@use '$/animation.scss';
@use '$/shadows.scss';
@use '$/countries.scss';

.map-filters-section {
  position: relative;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: spacings.$desktop-vertical-md;

  .map-wrapper {
    position: relative;

    display: grid;
    grid-template-columns: 1fr 264px;

    width: 100%;
    padding: 0 spacings.$desktop-horizontal-lg;

    .other-destinations {
      width: 264px;
    }
  }

  .title {
    font-size: typography.$font-size-xl;
    font-weight: 600;
    text-align: center;
  }

  .sub-title {
    font-size: typography.$mobile-sub-title;
  }

  .search-bar {
    :deep(.filters-container) {
      border: solid 1px colors.$grey-500;
    }

    :deep(.filter-text-container) {
      p {
        color: colors.$grey-500;
      }
    }
  }

  .toast {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: spacings.$desktop-horizontal-lg;

    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    align-self: center;

    width: fit-content;
    max-height: 200px;
    padding: 8px 32px;

    color: colors.$gold;

    background-color: white;
    border-radius: border-radius.$default;
    box-shadow: shadows.$page;
  }

  .map-continent {
    margin-top: spacings.$desktop-vertical-md;

    &:deep(path) {
      pointer-events: none;
    }

    @each $l in countries.$locations {
      &:deep(svg ##{$l}) {
        pointer-events: unset;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          fill: colors.$green;
          transition: 0.2s;
        }

        &.selected {
          fill: colors.$green;

          &:hover {
            fill: silver !important;
          }
        }
      }

      &:deep(.hovered) {
        cursor: pointer;
        opacity: 0.7;
        fill: colors.$green;
        transition: 0.2s;

        &.selected {
          fill: silver !important;
        }
      }
    }
  }

  .map-continent-loading {
    z-index: 1;
    margin-top: spacings.$desktop-vertical-lg;

    :deep(svg) {
      path {
        fill: #f5f5f5;
        transition: 0.2s;
      }
    }
  }
}

.pointer-container {
  position: absolute;
  z-index: 2;

  display: none;
  gap: 4px;
  align-items: center;
  justify-content: center;

  padding: 8px 32px;

  color: colors.$gold;

  background-color: white;
  border-radius: border-radius.$default;
  box-shadow: shadows.$page;

  transition: 0.1s linear;
}

@include breakpoints.mobile() {
  .map-filters-section {
    align-items: flex-start;

    .map-wrapper {
      position: relative;

      display: none;
      flex-direction: column;

      width: 100%;
      padding: 0 spacings.$desktop-horizontal-lg;
    }

    .title {
      padding: 0 spacings.$mobile-horizontal;
      font-size: typography.$mobile-h2;
      text-align: left;
    }

    .sub-title {
      padding: 0 spacings.$mobile-horizontal;
      font-size: typography.$mobile-body-l;
    }
  }
}
</style>
