<template>
  <div class="search-container">
    <div class="filters-container">
      <div
        class="filter"
        :class="{
          active: selectedDestinationValue.length > 0
        }"
        @click="handleRemoveFiltersByType('destination')"
      >
        <Icon name="destination-filter" />
        <div class="filter-text-container">
          <span>Destination</span>
          <div v-if="selectedDestinationValue.length > 0" class="filter-name">
            <div v-for="element in selectedDestinationValue" :key="element.text">
              {{ element.text }}
            </div>
          </div>
          <p v-else>Où partir ?</p>
        </div>
        <div v-if="isShowSearchBar" class="dropdown-wrapper search-dropdown">
          <div class="hidden" />
          <div ref="locationFilterRef" class="dropdown">
            <InputsAutocompleteInput
              :model-value="destinationName"
              class="input-searchbar"
              :options="destinationsOptions"
              placeholder="Ex : Japon, Guatemala, Afrique du Sud..."
              @on-name-update="
                (args: any) => {
                  handleOnDestinationNameUpdate(args);
                }
              "
              @on-option-choice="handleOnDestinationSelect"
            />
          </div>
        </div>
        <div v-else class="dropdown-wrapper">
          <div class="hidden" />
          <div ref="locationFilterRef" class="dropdown">
            <div
              v-for="stopovertown in fetchedFilterData.stopoverTownCategories"
              :key="stopovertown.id"
              class="item"
              :class="{ selected: isSelected('stopoverTownCategories', stopovertown.value) }"
              @click.stop="
                handleOnSelect('stopoverTownCategories', stopovertown.value, stopovertown.name)
              "
            >
              <div class="text">
                {{ stopovertown.name }}
              </div>
            </div>
          </div>
          <div class="overflow-container" @click="scrollDownLocationFilterRef">
            <Icon name="chevron" stroke="var(--color-green)" />
          </div>
        </div>
      </div>
      <div
        class="filter"
        :class="{ active: selectedSeasonsValue?.length && selectedSeasonsValue?.length > 0 }"
        @click="handleRemoveFiltersByType('seasons')"
      >
        <Icon name="date-filter" />
        <div class="filter-text-container">
          <span>Période</span>
          <p
            v-if="selectedSeasonsValue?.length && selectedSeasonsValue?.length > 0"
            class="filter-name"
          >
            <span v-for="season in selectedSeasonsValue" :key="season.text" class="label">{{
              season.text
            }}</span>
          </p>
          <p v-else>Quand partir ?</p>
        </div>
        <div class="dropdown-wrapper">
          <div class="hidden" />
          <div ref="monthFilterRef" class="dropdown">
            <div
              v-for="seadon in fetchedFilterData.seasons"
              :key="seadon.id"
              :class="{ selected: isSelected('seasons', seadon.value) }"
              class="item"
              @click.stop="handleOnSelect('seasons', seadon.value, seadon.name)"
            >
              <div class="text">
                {{ seadon.name }}
              </div>
            </div>
          </div>
          <div class="overflow-container" @click.stop="scrollDownMonthFilterRef">
            <Icon name="chevron" stroke="var(--color-green)" />
          </div>
        </div>
      </div>
      <div
        class="filter"
        :class="{
          active: selectedTravelStyleValue?.length && selectedTravelStyleValue?.length > 0
        }"
        @click="handleRemoveFiltersByType('typeCategories')"
      >
        <Icon name="type-filter" />
        <div class="filter-text-container">
          <span>Type de voyage</span>
          <p
            v-if="selectedTravelStyleValue?.length && selectedTravelStyleValue?.length > 0"
            class="filter-name"
          >
            <span v-for="i in selectedTravelStyleValue" :key="i.text" class="label">{{
              i.text
            }}</span>
          </p>
          <p v-else>Et votre envie ?</p>
        </div>
        <div class="dropdown-wrapper">
          <div class="hidden" />
          <div ref="travelStyleRef" class="dropdown">
            <div
              v-for="item in fetchedFilterData.typeCategories"
              :key="item.id"
              :class="{ selected: isSelected('typeCategories', item.value) }"
              class="item"
              @click.stop="handleOnSelect('typeCategories', item.value, item.name)"
            >
              <Icon v-if="item.icon" width="24px" height="24px" :name="item.icon" />
              <div class="text">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="overflow-container" @click.stop="scrollDownTravelStyleRef">
            <Icon name="chevron" stroke="var(--color-green)" />
          </div>
        </div>
      </div>
      <button
        :disabled="isDisabled"
        class="search-button"
        aria-label="search-button"
        @click="onSearch"
      >
        <Icon v-if="!isLoading" name="search" stroke="white" />
        <div v-else class="spinner"></div>
      </button>
    </div>

    <span
      v-if="selectedSeasonsValue?.length || destinations.length || selectedTravelStyleValue?.length"
      class="reset-filters-button"
      role="button"
      @click="resetFilters"
    >
      <Icon width="24px" height="24px" name="reinitialize" stroke="var(--color-green)" />
      <span class="reset-text">Réinitialiser les filtres</span>
    </span>
    <div class="filters-container-mobile-wrapper">
      <div class="filters-container-mobile">
        <div
          :class="{ active: selectedDestinationValue.length > 0 }"
          class="filter-item"
          @click="isOpenDestinationAccordion = !isOpenDestinationAccordion"
        >
          <div class="left">
            <Icon name="destination-filter" />
            <div class="filter-text-container">
              <span class="filter-title">Destination</span>

              <div v-if="selectedDestinationValue.length > 0">
                <p v-for="element in selectedDestinationValue" :key="element.text">
                  {{ element.text }}
                </p>
              </div>
              <p v-else>Où partir ?</p>
            </div>
          </div>
          <Icon :class="{ up: isOpenDestinationAccordion }" name="bottom" />
        </div>

        <div v-if="isOpenDestinationAccordion" class="accordion search-bar">
          <InputsAutocompleteInput
            :model-value="destinationName"
            class="input-searchbar"
            :options="destinationsOptions"
            placeholder="Ex : Japon, Guatemala, Afrique du Sud..."
            @on-name-update="
              (args: any) => {
                handleOnDestinationNameUpdate(args);
              }
            "
            @on-option-choice="handleOnDestinationSelect"
          />
        </div>
        <div class="separator-mobile"></div>
        <div
          :class="{ active: selectedSeasonsValue?.length && selectedSeasonsValue?.length > 0 }"
          class="filter-item"
          @click="isOpenMonthAccordion = !isOpenMonthAccordion"
        >
          <div class="left">
            <Icon name="date-filter" />
            <div class="filter-text-container">
              <span class="filter-title">Période</span>
              <div
                v-if="selectedSeasonsValue?.length && selectedSeasonsValue?.length > 0"
                class="filter-names-container"
              >
                <p>
                  <span v-for="i in selectedSeasonsValue" :key="i.text" class="label">{{
                    i.text
                  }}</span>
                </p>
              </div>
              <p v-else>Quand partir?</p>
            </div>
          </div>

          <Icon :class="{ up: isOpenMonthAccordion }" name="bottom" />
        </div>
        <div v-if="isOpenMonthAccordion" class="accordion">
          <span
            v-for="i in fetchedFilterData.seasons"
            :key="i.id"
            class="filter-element"
            :class="{ selected: isSelected('seasons', i.value) }"
            @click="handleOnSelect('seasons', i.value, i.name)"
          >
            {{ i.name }}
          </span>
        </div>
        <div class="separator-mobile"></div>
        <div
          class="filter-item"
          :class="{
            active: selectedTravelStyleValue?.length && selectedTravelStyleValue?.length > 0
          }"
          @click="isOpenTravelStyleAccordion = !isOpenTravelStyleAccordion"
        >
          <div class="left">
            <Icon name="type-filter" />
            <div class="filter-text-container">
              <span class="filter-title">Type de voyage</span>
              <p>Et votre style?</p>
            </div>
          </div>

          <Icon :class="{ up: isOpenTravelStyleAccordion }" name="bottom" />
        </div>
        <div v-if="isOpenTravelStyleAccordion" class="accordion">
          <span
            v-for="i in fetchedFilterData.typeCategories"
            :key="i.id"
            class="filter-element"
            :class="{ selected: isSelected('typeCategories', i.value) }"
            @click="handleOnSelect('typeCategories', i.value, i.name)"
          >
            {{ i.name }}
          </span>
        </div>
        <button
          :disabled="isDisabled"
          class="search-button-mobile"
          aria-label="search-button-mobile"
          @click="onSearch"
        >
          <Icon v-if="!isLoading" name="search" stroke="white" />
          <div v-else class="spinner"></div>
        </button>
      </div>
      <span class="reset-filters-button-mobile" role="button" @click="resetFilters">
        <Icon width="20px" height="20px" name="reinitialize" stroke="var(--color-green)" />
        Réinitialiser les filtres
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ROUTE_NAMES from '@/lib/types/enums/routeNames.enum';
import { Filter, FilterDefinition, FiltersObject } from '@/lib/types/filters';
import { SlugItem } from '@/lib/types/models/common';

const props = withDefaults(
  defineProps<{
    travelCount: number;
    fetchedFilterData: {
      stopoverTownCategories?: FilterDefinition<'stopoverTownCategories'>[];
      destinations?: SlugItem<string>[];
      seasons?: FilterDefinition<'seasons'>[];
      typeCategories?: FilterDefinition<'typeCategories'>[];
      wishCategories?: FilterDefinition<'wishCategories'>[];
    };
    selectedFilter: FiltersObject;
  }>(),
  {}
);

const emit = defineEmits<{
  (event: 'toggle-modal', toggled: boolean): void;
  (event: 'set-filters', filters: FiltersObject): void;
  (event: 'reset-filters'): void;
}>();

const travelStyleRef = ref<HTMLElement | null>();
const locationFilterRef = ref<HTMLElement | null>();
const monthFilterRef = ref<HTMLElement | null>();

const isOpenDestinationAccordion = ref(false);
const isOpenMonthAccordion = ref(false);
const isOpenTravelStyleAccordion = ref(false);

const selected = ref(props.selectedFilter);

const route = useRoute();
const { getIsLoading } = useLoadingStore();
const isLoading = computed(() => getIsLoading('gridResults'));

const isDisabled = computed(() => {
  return Object.keys(selected.value)
    .map(k => {
      if (Array.isArray(selected.value) && (selected.value[k] as any[])?.length > 0) {
        return true;
      } else if (
        (selected.value[k] as Filter | undefined)?.value === '' ||
        !selected.value[k] ||
        Object.keys(selected.value[k] as Filter).length === 0
      ) {
        return true;
      } else {
        return false;
      }
    })
    .every(i => i);
});

const selectedDestinationValue = computed(() => {
  if (selected.value.destinations) {
    return selected.value.destinations as Filter<SlugItem>[];
  }

  if (selected.value.stopoverTownCategories) {
    return selected.value.stopoverTownCategories as Filter<SlugItem>[];
  }

  return [];
});

const selectedSeasonsValue = computed(() => {
  if (selected.value.seasons) {
    return selected.value.seasons as Filter<SlugItem>[];
  }

  return null;
});

const selectedTravelStyleValue = computed(() => {
  if (selected.value.typeCategories) {
    return selected.value.typeCategories as Filter<SlugItem>[];
  }

  return null;
});

const {
  destinationsOptions,
  destinationName,
  destinations,
  handleOnDestinationNameUpdate,
  handleOnDestinationSelect,
  setDestinations
} = useSearchBarFilters();

const isShowSearchBar = computed(() => {
  if (route.path === '/' || route.name === ROUTE_NAMES.WHEN_WHERE_TO_GO) {
    return true;
  }

  return false;
});

watchEffect(() => {
  selected.value = props.selectedFilter;
  setDestinations((props.selectedFilter.destinations as Filter<SlugItem>[]) ?? []);
});

watch(destinations, () => {
  selected.value.destinations = destinations.value;
});

function emitOnSelect(): void {
  emit('set-filters', selected.value);
}

function resetFilters(): void {
  setDestinations([]);
  emit('reset-filters');
}

function scrollDownTravelStyleRef(): void {
  if (travelStyleRef.value)
    travelStyleRef.value.scrollTo({ top: travelStyleRef.value.scrollHeight, behavior: 'smooth' });
}

function scrollDownLocationFilterRef(): void {
  if (locationFilterRef.value)
    locationFilterRef.value.scrollTo({
      top: locationFilterRef.value.scrollHeight,
      behavior: 'smooth'
    });
}

function scrollDownMonthFilterRef(): void {
  if (monthFilterRef.value)
    monthFilterRef.value.scrollTo({ top: monthFilterRef.value.scrollHeight, behavior: 'smooth' });
}

function handleOnSelect(filterName: string, value: string | SlugItem, text: string): void {
  // TODO: Is it working? + Repetition

  let filterValue = selected.value[filterName];

  // User selected a single-toggle filter
  if (!filterValue) {
    filterValue = { identifier: filterName, text, value };
  } else if (Array.isArray(filterValue)) {
    // User un-selected a multi-toggle filter
    if (filterValue.some(i => i.value === value)) {
      filterValue = filterValue.filter(f => f.value !== value);
    }
    // User selected a multi-toggle filter
    else {
      filterValue = [...filterValue, { identifier: filterName, text, value }];
    }
  }
  // User un-selected a single-toggle filter
  else if (filterValue.value === value) {
    filterValue = null;
  } else {
    filterValue = { identifier: filterName, text, value };
  }

  selected.value[filterName] = filterValue;
}

function handleRemoveFiltersByType(type: string): void {
  const filterValue = selected.value[type];
  selected.value = {
    ...selected.value,
    [type]: Array.isArray(filterValue) ? [] : null
  };
}

function isSelected(key: string, value: string): boolean {
  const filterValue = selected.value[key];

  return Array.isArray(filterValue)
    ? filterValue.some(i => i.value === value)
    : filterValue?.value === value;
}

function onSearch(): void {
  isOpenDestinationAccordion.value = false;
  isOpenMonthAccordion.value = false;
  isOpenTravelStyleAccordion.value = false;
  emitOnSelect();
}
</script>

<style lang="scss" scoped>
@use '$/border-radius.scss';
@use '$/colors.scss';
@use '$/breakpoints.scss';
@use '$/shadows.scss';
@use '$/mouse-behavior.scss';
@use '$/spacings.scss';
@use '$/typography.scss';
@use '$/button.scss';
@use '$/misc.scss';

.search-container {
  $padding-spacing: 47px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr;
  column-gap: 24px;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 48px;
  padding: 0 spacings.$desktop-horizontal-lg;

  .reset-filters-button {
    @include misc.bold-link();

    display: grid;
    grid-column: 2 / span 3;
    grid-template-columns: 24px 1fr;
    gap: 8px;
    align-items: center;
    justify-self: center;

    margin-top: 1.5rem;
  }

  .reset-filters-button-mobile {
    @include misc.bold-link();

    display: none;
    gap: 8px;
    align-items: center;
    justify-content: center;

    margin-top: 16px;
  }

  .filters-container {
    display: grid;
    grid-column: 2 / 5;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) auto;
    justify-content: center;

    width: 100%;

    background: white;
    border-radius: border-radius.$default;

    .filter {
      cursor: pointer;

      position: relative;

      align-items: center;

      width: 100%;
      padding: 0 12px;
      padding-left: 30px;

      transition: 0.2s;

      &:first-child,
      &:nth-child(2) {
        border-right: solid 1px colors.$grey-500;
      }

      &:first-child {
        border-top-left-radius: border-radius.$default;
        border-bottom-left-radius: border-radius.$default;
      }

      &.active {
        background-color: colors.$light-green;
      }

      .filter-text-container {
        overflow: hidden;
        flex-direction: column;

        margin-left: 8px;

        text-overflow: ellipsis;
        white-space: nowrap;

        .filter-name {
          overflow: hidden;
          display: flex;
          flex-wrap: nowrap;
          gap: 8px;

          min-width: 0;

          text-overflow: ellipsis;
          white-space: nowrap;

          .label {
            font-weight: 400;
          }
        }

        span {
          font-weight: 600;
        }
      }

      &:hover {
        .dropdown-wrapper {
          display: flex;
        }
      }

      .dropdown-wrapper {
        position: absolute;
        z-index: 10;
        top: 70px;
        left: 0;

        display: none;

        width: 100%;
        max-height: 280px;
        margin-top: -12px;
        padding-top: 12px;

        &.search-dropdown {
          width: 300%;
          border-radius: border-radius.$small;

          .dropdown {
            overflow: visible;
            width: 300%;
            padding: 40px 32px;
            border-radius: border-radius.$small;
          }
        }

        // eslint-disable-next-line vue-scoped-css/no-unused-selector
        .input-searchbar {
          :deep(input) {
            width: 100%;
            height: 40px;
            background-color: colors.$grey-100;
          }

          &.autocomplete-wrapper {
            max-width: 100%;
          }

          :deep(.autocomplete-menu) {
            max-width: 100%;
          }
        }

        .hidden {
          position: absolute;
          top: -20px;

          width: 100%;
          height: 20px;

          background: transparent;
        }

        .dropdown {
          position: relative;

          overflow-y: scroll;
          flex-direction: column;

          width: 100%;
          margin-top: 12px;

          background-color: white;
          border-top-left-radius: border-radius.$default;
          border-top-right-radius: border-radius.$default;
          box-shadow: shadows.$page;

          transition: 0.2s;

          .item {
            @include mouse-behavior.clickable-alpha(background-color, colors.$grey-900);

            border: solid 1px transparent;
            border-bottom-width: 1px;

            :deep(.icon) {
              margin-left: 24px;
            }

            .text {
              justify-content: space-between;
              padding: 16px;
            }

            &.selected {
              color: colors.$green;
              background-color: colors.$light-green;

              &:first-child {
                border-top-left-radius: border-radius.$default;
                border-top-right-radius: border-radius.$default;
              }
            }
          }
        }

        .overflow-container {
          position: absolute;
          bottom: -40px;

          align-items: center;
          justify-content: center;

          width: 100%;
          height: 40px;

          background-color: white;
          border-bottom-right-radius: border-radius.$default;
          border-bottom-left-radius: border-radius.$default;
          box-shadow: shadows.$page;
        }
      }
    }

    .search-button {
      @include button.plain(colors.$green, small);

      width: 56px;
      height: 100%;
      height: 56px;
      margin: 8px;

      border-radius: border-radius.$default;

      .spinner {
        @include misc.loader($color: white, $size: 20px, $border-width: 2px);
      }
    }
  }

  .filters-container-mobile-wrapper {
    display: none;

    .filters-container-mobile {
      display: none;
    }
  }
}

@include breakpoints.tablet() {
  .search-container {
    grid-template-columns: 1fr 2fr auto;

    .reset-filters-button {
      grid-column: 1 / span 3;
    }

    .filters-container {
      display: grid;
      grid-column: 1 / 3;
    }
  }
}

@include breakpoints.mobile() {
  .search-container {
    display: flex;
    margin-top: 24px;
    padding: 0 spacings.$mobile-horizontal;

    .filters-container {
      display: none;
    }

    .reset-filters-button {
      display: none;
    }

    .filters-container-mobile-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .filters-container-mobile {
        display: flex;
        flex-direction: column;

        max-width: auto;

        background-color: white;
        border: solid 1px colors.$grey-300;
        border-radius: border-radius.$default;

        .separator-mobile {
          display: flex;
          width: 100%;
          height: 1px;
          background-color: rgba(187 187 187 / 80%);
        }

        .filter-item {
          cursor: pointer;
          justify-content: space-between;
          padding: 12px 16px;
          transition: 0.2s;

          &.active {
            background-color: colors.$light-green;
          }

          .up {
            transform: rotate(180deg);
          }

          &:hover {
            background-color: colors.$light-green;
          }

          &:last-child {
            border-bottom-right-radius: border-radius.$default;
            border-bottom-left-radius: border-radius.$default;
          }

          &:first-child {
            border-top-left-radius: border-radius.$default;
            border-top-right-radius: border-radius.$default;
          }

          .left {
            cursor: pointer;
            align-items: center;

            .filter-text-container {
              flex-direction: column;
              margin-left: 8px;

              .filter-title {
                font-weight: 600;
              }

              .filter-names-container {
                display: flex;
              }
            }
          }
        }

        .accordion {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(4, 1fr);
          gap: 8px;

          padding: 12px 16px;

          text-overflow: ellipsis;
          white-space: nowrap;

          &.search-bar {
            display: flex;
            padding: 12px;
          }

          .filter-element {
            @include mouse-behavior.clickable-opacity();

            justify-content: space-between;
            padding: 5px;
            border: solid 1px colors.$grey-300;
            border-radius: border-radius.$small;

            &.selected {
              color: colors.$green;
              background-color: colors.$light-green;
              border-color: colors.$green;
            }
          }
        }

        .search-button-mobile {
          @include button.plain(colors.$green, large);

          margin: 0 16px 16px;

          .spinner {
            @include misc.loader($color: white, $size: 20px, $border-width: 2px);
          }
        }
      }

      .reset-filters-button-mobile {
        display: flex;
      }
    }
  }
}
</style>
