import { Category } from '@/lib/strapi-types/Category';

import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Filter } from '@/lib/types/filters';
import { SlugItem } from '@/lib/types/models/common';

export default function useSearchBarFilters() {
  const { find } = useStrapi();
  const destinationName = ref('');
  const destinations = ref<Filter<SlugItem>[]>([]);
  const destinationsOptions = ref<SlugItem[]>([]);

  async function handleOnDestinationNameUpdate(v: string): Promise<void> {
    destinationName.value = v;
    if (v.length < 3) {
      destinationsOptions.value = [];
      return;
    }

    const countries = await find<Category['attributes']>(BackEndpoints.CATEGORIES, {
      filters: {
        title: {
          $contains: destinationName.value
        },
        categoryMdvAttribute: 'country'
      },
      sort: ['title:ASC']
    });

    destinationsOptions.value =
      countries?.data.map(
        country =>
          ({
            slug: country.attributes.urlKey,
            name: country.attributes.title,
            type: 'country',
            code: country.attributes.mdvDstMapsShortname
          }) as SlugItem
      ) ?? [];
  }

  function handleOnDestinationSelect(item: SlugItem): void {
    if (destinations.value.find(e => e.text === item.name))
      destinations.value = destinations.value.filter(e => e.text !== item.name);
    else
      destinations.value = [
        ...destinations.value,
        {
          identifier: 'destination',
          text: item.name,
          value: item
        }
      ];
  }
  function setDestinations(destinationFilter: Filter<SlugItem>[]): void {
    destinations.value = destinationFilter;
  }

  return {
    handleOnDestinationNameUpdate,
    destinationName,
    destinations,
    destinationsOptions,
    handleOnDestinationSelect,
    setDestinations
  };
}
